'use strict';

angular.module('cpformplastApp.items')
  .controller('mechanicPlanningController', function ($scope, Util, DataManager, Auth, $state, Notification) {

    $scope.tableStructure = [
      {
        key: "machine",
        title: "MACH",
        widthPct: 5,
        minWidth: 80,
        style: "text-align:center;font-weight:bold;"
      },
      {
        key: "mold",
        title: "# MOULE",
        widthPct: 5,
        minWidth: 100,
        style: "text-align:center;"
      },
      {
        key: "client",
        title: "CLIENT",
        widthPct: 15,
        minWidth: 300,
        style: "text-align:center;"
      },
      {
        key: "item",
        title: "MOULE",
        widthPct: 15,
        minWidth: 300,
        style: ""
      },
      {
        key: "ups",
        title: "UPS",
        widthPct: 5,
        minWidth: 60,
        style: "text-align:center;"
      },
      {
        key: "material",
        title: "MATÉRIEL",
        widthPct: 15,
        minWidth: 200,
        style: ""
      },
      {
        key: "requiredDate",
        title: "DATE",
        widthPct: 10,
        minWidth: 120,
        style: "border-right: 1px solid #ddd; border-left: 1px solid #ddd; text-align:center;font-weight:bold;"
      },
      {
        key: "mechanic",
        title: "MÉCANO",
        widthPct: 15,
        minWidth: 200,
        style: ""
      },
      {
        key: "itemStart",
        title: "DÉBUT",
        widthPct: 15,
        minWidth: 180,
        style: "text-align:center; padding:0;"
      },
      {
        key: "itemEnd",
        title: "FIN",
        widthPct: 15,
        minWidth: 180,
        style: "text-align:center; padding:0;"
      },
      {
        key: "setupEnd",
        title: "FIN SET-UP",
        widthPct: 15,
        minWidth: 180,
        style: "text-align:center; padding:0;"
      }
    ];

    $scope.weeks = [];
    $scope.searchString = "";
    $scope.minDate = new Date();

    $scope.machineType = "manual";
    $scope.isPreviewMode = false;
    const PreviewModeOnlyRoles = ["management_dept"]


    // UI

    $scope.weekToPrint = "None";
    $scope.printContent = function () {
      let weekIndices = [];

      for (var i = 0; i < $scope.weeks.length; i++) {
        // Print every week if "All" was selected, else print only specified week
        if ($scope.weekToPrint === "All" || $scope.weekToPrint === $scope.weeks[i].title) {
          weekIndices.push(i);
        }
      }

      // Reset selection
      $scope.weekToPrint = "None";
      const select = document.getElementById("print-select");
      select.selectedIndex = 0;

      // Actually download the thing!
      DataManager.downloadMechanicSchedulePdf(weekIndices, $scope.machineType);
    };

    //removes schedules where the setupEndDate is set
    $scope.filterWeeks = function () {
      $scope.weeks = $scope.weeks.filter(week =>{
        week.schedules = week.schedules.filter(schedule=> schedule.itemSchedule.setupEndDate == null);
        return week.schedules.length > 0;
      });
    }

    // NETWORKING

    $scope.fetchSchedules = function () {
      DataManager.fetchSubjobSchedules('week', $scope.machineType, null, 'mechanic')
        .then(weeks => {
          $scope.weeks = weeks;
          $scope.adjustPageSize();
        })
        .catch(err => {
          console.log(err);
          Notification.error('Un problème est survenu lors du chargement des cédules');
        });
    };

    $scope.updateSubjobSchedule = function (subjobSchedule) {
      subjobSchedule.itemSchedule.mechanicChanged = false;

      return DataManager.updateSubjobSchedule(subjobSchedule, 'single')
        .then(schedule => {
          subjobSchedule = schedule;
          $scope.filterWeeks();
        })
        .catch(err => {
          console.log(err);
          Notification.error('Un problème est survenu');
        });
    };

    // DOM Management

    $scope.adjustPageSize = function () {
      const pageHeight = $(window).height();
      const topnavHeight = $('#topnav').height();
      const breadcrumbHeight = $('.breadcrumb').height();
      const searchRowHeight = $('.search-row').height();
      const headerHeight = $('.planning-header').height();

      const weekListHeight = pageHeight - topnavHeight - breadcrumbHeight - headerHeight - 120;
      $('.week-list').height(weekListHeight);
    };

    $scope.setIsPreviewMode = function () {
      Auth.getCurrentUser(function (user, err) {
        $scope.isPreviewMode = PreviewModeOnlyRoles.findIndex(x => x === user.role) !== -1
      })
    }
    // INIT

    $scope.init = function () {
      $scope.fetchSchedules();
      $scope.setIsPreviewMode();

      // Those roles can only see the information


      $(window).resize(function () {
        $scope.adjustPageSize();
      });
    };

    $scope.init();

  });
